import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "Should Engineers Design?",
  "link": "http://blog.capwatkins.com/should-engineers-design/",
  "date": "2014-08-05T00:00:00.000Z",
  "tags": "Design Development",
  "published": true
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><a parentName="p" {...{
        "href": "http://blog.capwatkins.com/should-engineers-design/"
      }}>{`Cap Watkins`}</a>{` replies to designers who ask “Why should I learn to code if engineers don’t have to learn to design?“:`}</p>
    <blockquote>
      <p parentName="blockquote">{`Throughout my entire career I’ve had engineering partners deep in the design process with me. I show them sketches, bounce ideas off of them, have whiteboarding sessions to figure out what we’re going to do. I trust engineers I work with to let me know when something seems confusing, when there’s an edge case I haven’t thought of and to push on my ideas to find where they break and help me make them even better. I can’t count the number of times I was saved from a bad direction by talking it through with my product team. I’ve personally worked with engineers who are even more staunch user-advocates than I am.`}</p>
    </blockquote>
    <p>{`Amen. At Shopify, we’ve conciously made the decision to blur the lines between design and development for that reason. Designers and developers approach problems from a different point of view, but ultimately share a common goal: making the product better. Magic happens when you put smart people who can challenge and feed off of each other in the same room.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      